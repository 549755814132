import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';

import Injector from './core/utils/Injector';
import Routes from './Routes';

const basePath = Injector.config('basePath');

const Router = () => {
  useEffect(() => {
    // Force Redirection to an external domain name
    if (Injector.config('is_redirect_mybrandstream_to_larchworkflow')) {
      const currentUrlOrigin = window.location.origin;

      if (currentUrlOrigin.includes('mybrandstream')) {
        const {pathname, search} = window.location;
        const newUrl = currentUrlOrigin.replace('mybrandstream', 'larchworkflow') + pathname + search;

        window.location.replace(newUrl);
      }
    }
  }, []);


  return (
    <BrowserRouter basename={basePath}>
      <Routes />
    </BrowserRouter>
  );
};

export default Router;
